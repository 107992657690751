import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { CSVLink } from "react-csv";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import TableSeries from "../components/series/Table";
import { useNavigator } from "../hooks/useNavigator";
import { getAllSeries } from "../utils/api";

const Series = () => {
  const [series, setSeries] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const getAllSeriesHandler = async () => {
    if (user?.getSeries) {
      const [success, data] = await getAllSeries();
      if (success) {
        setSeries(data);
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {
    if (user) {
      getAllSeriesHandler();
    } else {
      navigate("/login");
    }
  });

  // Prepare CSV data for series
  const prepareSeriesCSVData = () => {
    return series.map((seriesItem) => {
      return {
        name: seriesItem.name || "",
        description: seriesItem.description || "",
        views: seriesItem.view || 0,
        duration: seriesItem.duration || "",
        rating: seriesItem.rating || "",
        language: seriesItem.language || "",
        ageLimit: seriesItem.ageLimit || "",
        isFree: seriesItem.isFree ? "Yes" : "No",
        isEnable: seriesItem.isEnable ? "Yes" : "No",
        episodesCount: seriesItem.episodes?.length || 0,
        cast: seriesItem.cast?.map((c) => c.name).join(", ") || "",
        genres: seriesItem.genres?.map((g) => g.name).join(", ") || "",
      };
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          {user?.addSeries && (
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                navigate("/series/create");
              }}
            >
              Create Series
            </Button>
          )}

          {user?.getSeries && (
            <Button
              as={CSVLink}
              data={prepareSeriesCSVData()}
              filename={"series-data.csv"}
              colorScheme="green"
              variant="solid"
            >
              Download Series CSV
            </Button>
          )}
        </Box>

        {user?.getSeries ? (
          <TableSeries
            series={series}
            actions
            reload={getAllSeriesHandler}
            csvData={prepareSeriesCSVData()}
            csvFilename="series-data.csv"
          />
        ) : (
          <Fallback />
        )}
      </Box>
    </>
  );
};

export default Series;
