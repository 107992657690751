import { Box, Button, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import Modal from "../components/podcasts/Modal";
import Table from "../components/podcasts/Table";
import { useNavigator } from "../hooks/useNavigator";
import { createNewMovie, getAllMovies, getSubCategories } from "../utils/api";

const Movies = () => {
  const toast = useToast();
  const [allMovies, setAllMovies] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getAllMoviesHandler = async () => {
    setLoading(true);
    if (user?.getMovies) {
      const [success, data] = await getAllMovies();
      if (success) {
        setAllMovies(data);
      } else {
        toast({
          title: "Error",
          description: data,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {
    if (user) {
      getAllMoviesHandler();
    } else {
      navigate("/login");
    }
  });

  const createNewMovieHandler = async (newPodcast, id) => {
    setLoading(true);
    if (user?.addMovies) {
      const [success, res] = await createNewMovie(newPodcast);
      if (success) {
        toast({
          title: "Video Created",
          description: "Video Created Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        getAllMoviesHandler();
      } else {
        toast({
          title: "Error",
          description: "Error Creating Movie",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };

  const [categories, setCategories] = React.useState([]);
  const getAllCategories = async () => {
    const [success, data] = await getSubCategories();
    if (success) {
      setCategories(data);
    }
  };

  // Prepare CSV data for movies
  const prepareMoviesCSVData = () => {
    return allMovies.map((movie) => {
      return {
        name: movie.name || "",
        description: movie.description || "",
        views: movie.view || 0,
        duration: movie.duration || "",
        rating: movie.rating || "",
        language: movie.language || "",
        ageLimit: movie.ageLimit || "",
        isFree: movie.isFree ? "Yes" : "No",
        isEnable: movie.isEnable ? "Yes" : "No",
        subcategory: movie.subcategory?.title || "",
        cast: movie.cast?.map((c) => c.name).join(", ") || "",
        genres: movie.genres?.map((g) => g.name).join(", ") || "",
      };
    });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          gap: 10,
        }}
      >
        {user?.addMovies && (
          <Modal
            save={createNewMovieHandler}
            title={"Add Video"}
            categories={categories}
          />
        )}

        {user?.getMovies && (
          <Button
            as={CSVLink}
            data={prepareMoviesCSVData()}
            filename={"movies-data.csv"}
            colorScheme="green"
            variant="solid"
          >
            Download Movies CSV
          </Button>
        )}
      </Box>

      {user?.getMovies ? (
        <Table
          allPodcasts={allMovies}
          actions={true}
          reload={getAllMoviesHandler}
          subcategories={categories}
          csvData={prepareMoviesCSVData()}
          csvFilename="movies-data.csv"
        />
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default Movies;
