import {
  Box,
  Button,
  FormLabel,
  Switch,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { CSVLink } from "react-csv";
import Select from "react-select";
import DeleteModal from "../components/common/Modal/DeleteModal";
import CreateModal from "../components/common/Modal/Modal";
import CreateTable from "../components/common/Table/Table";
import CreateInput from "../components/common/TextInputs/Input";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import useActions from "../hooks/useActions";
import { useNavigator } from "../hooks/useNavigator";
import {
  addDataToSubcription,
  addSubscribtion,
  deleteSubscription,
  getAllMovies,
  getAllSeries,
  getAllSubscribtions,
  subscribtionsCheckMovie,
  subscribtionsCheckSeries,
  updateSubscription,
} from "../utils/api";

const AddSubcription = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [movie, setMovie] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [ids, setIds] = React.useState([]);

  const getAllMoviesHandler = async () => {
    const [success, data] = await getAllMovies();
    if (success) {
      setMovie(data);
    }
  };

  const getAllSeriesHandler = async () => {
    const [success, data] = await getAllSeries();
    if (success) {
      setSeries(data);
    }
  };

  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const defaultData = {
    cashbackEnable: false,
    cashbackAmount: 0,
  };
  const [data, setData] = React.useState(defaultData);
  const [update, setUpdate] = React.useState(false);
  const [add, setAdd] = React.useState({
    movie: false,
    series: false,
    id: "",
  });

  const [loading, setLoading] = React.useState(true);
  const [subscribtions, setSubscribtions] = React.useState([]);
  const toast = useToast();
  console.log(data);
  const [updateAction, deleteAction] = useActions();
  const onCreateSubcription = async () => {
    setLoading(true);
    if (
      data.title &&
      data.description &&
      data.price !== undefined &&
      data.validity !== undefined &&
      (data.isRewardAvailable
        ? data.rewardCoin && data.totalRewardVideo && data.userRewardVideo
        : true)
    ) {
      if (!update) {
        if (user?.addSubscription) {
          const [status, res] = await addSubscribtion(data);
          if (status) {
            toast({
              title: "Subcription Created",
              description: "Subcription Created Successfully",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            getAllSubscribtionsHandler();
            onClose();
          } else {
            toast({
              title: "Subcription Not Created",
              description: "Subcription Not Created Successfully",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        }
      } else {
        if (user?.updateSubscription) {
          await updateAction(data, data._id, updateSubscription);
          onClose();
          getAllSubscribtionsHandler();
          setUpdate(false);
          setData(defaultData);
        }
      }
    } else {
      toast({
        title: "Fill All Fields",
        description: "Subcription Not Created Successfully",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const getAllSubscribtionsHandler = async () => {
    setLoading(true);
    const [status, res] = await getAllSubscribtions();
    if (status) {
      setSubscribtions(res);
    } else {
      toast({
        title: "Subcriptions Not Found",
        description: "Subcriptions Not Found Successfully",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {
    if (user?.getSubscription) {
      getAllSubscribtionsHandler();
      getAllMoviesHandler();
      getAllSeriesHandler();
    } else {
      setLoading(false);
    }
  });

  // Prepare CSV data for subscriptions
  const prepareSubscriptionsCSVData = () => {
    return subscribtions.map((subscription) => {
      return {
        title: subscription.title,
        description: subscription.description,
        price: subscription.price,
        validity: subscription.validity,
        moviesCount: subscription.moviesCount || 0,
        seriesCount: subscription.seriesCount || 0,
        cashbackEnabled: subscription.cashbackEnable ? "Yes" : "No",
        cashbackAmount: subscription.cashbackAmount || 0,
      };
    });
  };

  console.log(data);
  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      {user?.getSubscription ? (
        <Box>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
            <Button
              onClick={() => {
                setData(defaultData);
                setUpdate(false);
                onOpen();
              }}
              variant="solid"
              colorScheme="teal"
            >
              Create Subcription
            </Button>

            <Button
              as={CSVLink}
              data={prepareSubscriptionsCSVData()}
              filename={"subscriptions-data.csv"}
              colorScheme="green"
              variant="solid"
            >
              Download Subscriptions CSV
            </Button>
          </Box>
          <CreateTable
            head={[
              "Actions",
              "Title",
              "Description",
              "Price",
              "Validity",
              "Movies Count",
              "Series Count",
            ]}
            data={subscribtions}
            rows={(row) => {
              return (
                <>
                  <Td>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <>
                        <Button
                          style={{
                            width: "100%",
                          }}
                          colorScheme="blue"
                          variant="solid"
                          onClick={() => {
                            onOpen();
                            setUpdate(true);
                            setData(row);
                          }}
                        >
                          Edit Subcription
                        </Button>
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          onClick={async () => {
                            if (!user?.updateSubscription) return;
                            const [success, data] =
                              await subscribtionsCheckMovie(row._id);

                            if (success) {
                              setAdd({
                                movie: true,
                                series: false,
                                id: row._id,
                              });
                              setMovie(data);
                              onOpenAdd();
                            }
                          }}
                          sx={{
                            width: "100%",
                          }}
                        >
                          Add Movies
                        </Button>
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          onClick={async () => {
                            if (!user?.updateSubscription) return;
                            const [success, data] =
                              await subscribtionsCheckSeries(row._id);
                            if (success) {
                              setSeries(data);
                              setAdd({
                                movie: false,
                                series: true,
                                id: row._id,
                              });
                              onOpenAdd();
                            }
                          }}
                          sx={{
                            width: "100%",
                          }}
                        >
                          Add Series
                        </Button>
                      </>
                      {user?.deleteSubscription && (
                        <DeleteModal
                          onClick={async () => {
                            setLoading(true);
                            await deleteAction(row._id, deleteSubscription);
                            getAllSubscribtionsHandler();
                            setUpdate(false);
                            setData({});
                            setLoading(false);
                          }}
                        />
                      )}
                    </Box>
                  </Td>
                  <Td>{row.title}</Td>
                  <Td>{row.description.slice(0, 30)}...</Td>
                  <Td>{row.price}</Td>
                  <Td>{row.validity}</Td>
                  <Td>{row.moviesCount}</Td>
                  <Td>{row.seriesCount}</Td>
                </>
              );
            }}
          />
          <CreateModal
            onClose={onCloseAdd}
            isOpen={isOpenAdd}
            title={add.movie ? "Add Movies" : "Add Series"}
            create={async () => {
              setLoading(true);
              const [status, res] = await addDataToSubcription({
                [add.movie ? "movieIds" : "seriesIds"]: ids,
                type: add.movie ? "Movie" : "Series",
                subscriptionId: add.id,
              });
              if (status) {
                toast({
                  title: "Data Added",
                  description: "Data Added Successfully",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
                onCloseAdd();
                getAllSubscribtionsHandler();
              } else {
                toast({
                  title: "Data Not Added",
                  description: "Data Not Added Successfully",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
              setLoading(false);
            }}
          >
            <Select
              isMulti
              onChange={(e) => {
                setIds(e.map((e) => e.value));
              }}
              options={
                add.movie
                  ? movie?.map((movie) => ({
                      value: movie._id,
                      label: movie.name,
                    }))
                  : series.map((series) => ({
                      value: series._id,
                      label: series.name,
                    }))
              }
            />
          </CreateModal>
          <CreateModal
            onClose={onClose}
            isOpen={isOpen}
            title={update ? "Edit Subcription" : "Create Subcription"}
            create={onCreateSubcription}
          >
            <CreateInput
              name="Title"
              onChange={(e) =>
                setData((prev) => ({ ...prev, title: e.target.value }))
              }
              value={data.title}
            />
            <CreateInput
              name="Description"
              onChange={(e) =>
                setData((prev) => ({ ...prev, description: e.target.value }))
              }
              value={data.description}
            />
            <CreateInput
              name="Price"
              type={"number"}
              onChange={(e) =>
                setData((prev) => ({ ...prev, price: e.target.value }))
              }
              value={data.price}
            />
            <CreateInput
              name="Validity"
              type={"number"}
              onChange={(e) =>
                setData((prev) => ({ ...prev, validity: e.target.value }))
              }
              value={data.validity}
            />
            <FormLabel>
              Cashback Enable? {data?.cashbackEnable ? "Yes" : "No"}
            </FormLabel>
            <Switch
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  cashbackEnable: e.target.checked,
                }));
              }}
              isChecked={data?.cashbackEnable}
            />
            {data?.cashbackEnable && (
              <CreateInput
                name="Cashback Amount"
                type={"number"}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    cashbackAmount: e.target.value,
                  }))
                }
                value={data.cashbackAmount}
              />
            )}
            <FormLabel>
              Is Reward Available? {data?.isRewardAvailable ? "Yes" : "No"}
            </FormLabel>
            <Switch
              onChange={(e) => {
                console.log(e.target.checked);
                setData((prev) => ({
                  ...prev,
                  isRewardAvailable: e.target.checked,
                }));
              }}
              isChecked={data?.isRewardAvailable}
            />

            {data?.isRewardAvailable && (
              <>
                <CreateInput
                  name="Reward Coin"
                  type={"number"}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, rewardCoin: e.target.value }))
                  }
                  value={data.rewardCoin}
                />
                <CreateInput
                  name="Total Reward Video"
                  type={"number"}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      totalRewardVideo: e.target.value,
                    }))
                  }
                  value={data.totalRewardVideo}
                />
                <CreateInput
                  name="User Reward Video"
                  type={"number"}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      userRewardVideo: e.target.value,
                    }))
                  }
                  value={data.userRewardVideo}
                />
              </>
            )}
          </CreateModal>
        </Box>
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default AddSubcription;
