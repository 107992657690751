import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { CSVLink } from "react-csv";
import CreateTable from "../components/common/Table/Table";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import { useNavigator } from "../hooks/useNavigator";
import {
  acceptDenyRedeemRequest,
  getDashboardData,
  getRedeemRequest,
} from "../utils/api";

const RedeemRequest = () => {
  const [requests, setRequests] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [adminCharges, setAdminCharges] = React.useState(0);
  const getAllRequests = async () => {
    const [success, data] = await getRedeemRequest();
    if (success) {
      setRequests(data);
    }
    const [success2, data2] = await getDashboardData();
    if (success2) {
      setAdminCharges(data2?.systemValues?.adminCharges);
    }
    setLoading(false);
  };

  const [navigate, user] = useNavigator(() => {
    if (user) {
      getAllRequests();
    } else {
      navigate("/login");
    }
  });

  // Prepare CSV data for new requests
  const prepareNewRequestsCSVData = () => {
    return (
      requests?.created?.map((request) => {
        return {
          createdAt: request?.createdAt || "",
          status: request?.status || "",
          userName: request?.user?.name || "",
          userEmail: request?.user?.email || "",
          withdrawableCoins: request?.user?.totalCoin - adminCharges || 0,
          upi: request?.user?.upi || "",
          aadharNumber: request?.user?.aadharNumber || "",
          accountNumber: request?.user?.accountNumber || "",
          bankName: request?.user?.bankName || "",
          branchName: request?.user?.branchName || "",
          ifsc: request?.user?.ifsc || "",
        };
      }) || []
    );
  };

  // Prepare CSV data for completed requests
  const prepareCompletedRequestsCSVData = () => {
    return (
      requests?.completed?.map((request) => {
        return {
          createdAt: request?.createdAt || "",
          message: request?.message || "",
          status: request?.status || "",
          userName: request?.user?.name || "",
          userEmail: request?.user?.email || "",
          withdrawableCoins: request?.user?.totalCoin - adminCharges || 0,
          upi: request?.user?.upi || "",
          aadharNumber: request?.user?.aadharNumber || "",
          accountNumber: request?.user?.accountNumber || "",
          bankName: request?.user?.bankName || "",
          branchName: request?.user?.branchName || "",
          ifsc: request?.user?.ifsc || "",
        };
      }) || []
    );
  };

  // Prepare CSV data for declined requests
  const prepareDeclinedRequestsCSVData = () => {
    return (
      requests?.decline?.map((request) => {
        return {
          createdAt: request?.createdAt || "",
          message: request?.message || "",
          status: request?.status || "",
          userName: request?.user?.name || "",
          userEmail: request?.user?.email || "",
          withdrawableCoins: request?.user?.totalCoin - adminCharges || 0,
          upi: request?.user?.upi || "",
          aadharNumber: request?.user?.aadharNumber || "",
          accountNumber: request?.user?.accountNumber || "",
          bankName: request?.user?.bankName || "",
          branchName: request?.user?.branchName || "",
          ifsc: request?.user?.ifsc || "",
        };
      }) || []
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>New Requests</Tab>
          <Tab>Completed</Tab>
          <Tab>Delined</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
              <Button
                as={CSVLink}
                data={prepareNewRequestsCSVData()}
                filename={"new-redeem-requests.csv"}
                colorScheme="green"
                variant="solid"
              >
                Download New Requests CSV
              </Button>
            </Box>
            <Table
              data={requests?.created}
              actions
              reload={getAllRequests}
              setLoading={setLoading}
              charges={adminCharges}
            />
          </TabPanel>
          <TabPanel>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
              <Button
                as={CSVLink}
                data={prepareCompletedRequestsCSVData()}
                filename={"completed-redeem-requests.csv"}
                colorScheme="green"
                variant="solid"
              >
                Download Completed Requests CSV
              </Button>
            </Box>
            <Table data={requests?.completed} />
          </TabPanel>
          <TabPanel>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
              <Button
                as={CSVLink}
                data={prepareDeclinedRequestsCSVData()}
                filename={"declined-redeem-requests.csv"}
                colorScheme="green"
                variant="solid"
              >
                Download Declined Requests CSV
              </Button>
            </Box>
            <Table data={requests?.decline} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default RedeemRequest;
// upi: '7045771038@ptsbi',
// aadharNumber: '320419629007',
// accountNumber: '1846370302',
// bankName: 'Kotak Mahindra Bank',
// branchName: 'MIRA ROAD MUMBAI',
// ifsc: 'KKBK0001418',
// panNumber: 'CGEPT2092M',
const Table = ({ data, actions, reload, setLoading, charges }) => {
  console.log(data);
  const toast = useToast();
  const [navigate, user] = useNavigator(() => {});
  const approveDenyRequest = async (id, request) => {
    setLoading(true);
    const [success, data] = await acceptDenyRedeemRequest(id, request);
    if (success) {
      toast({
        title: "Success",
        description: data.message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      reload();
    } else {
      toast({
        title: "Error",
        description: data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  return user?.addAdmin ? (
    <CreateTable
      data={data}
      head={[
        "created_at",
        !actions && "message",
        "status",
        "user_name",
        "user_email",
        "Withdrwable Coins",
        "UPI",
        "Aadhar Number",
        "Account Number",
        "Bank Name",
        "Branch Name",
        "IFSC",
        actions && "actions",
      ]}
      rows={(row) => (
        <>
          <Td>{row?.createdAt}</Td>
          {!actions && <Td>{row?.message}</Td>}
          <Td>{row?.status}</Td>
          <Td>{row?.user?.name}</Td>
          <Td>{row?.user.email}</Td>
          <Td>{row?.user?.totalCoin - charges}</Td>
          <Td>{row?.user?.upi}</Td>
          <Td>{row?.user?.aadharNumber}</Td>
          <Td>{row?.user?.accountNumber}</Td>
          <Td>{row?.user?.bankName}</Td>
          <Td>{row?.user?.branchName}</Td>
          <Td>{row?.user?.ifsc}</Td>
          {actions && (
            <Td>
              <Box
                sx={{ display: "flex", gap: "10px", flexDirection: "column" }}
              >
                <Button
                  colorScheme="green"
                  onClick={() =>
                    approveDenyRequest(row._id, {
                      status: "Completed",
                      message: "Redeem Request has been accepted",
                      userId: row.user._id,
                    })
                  }
                >
                  Approve
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() =>
                    approveDenyRequest(row._id, {
                      status: "Decline",
                      message: "Redeem Request has been Decline",
                      userId: row.user._id,
                    })
                  }
                >
                  Decline
                </Button>
              </Box>
            </Td>
          )}
        </>
      )}
    />
  ) : (
    <Fallback />
  );
};
