import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import ReactPlayer from "react-player";

const VideoPlayerModal = ({
  isOpen,
  onClose,
  url,
  title = "Video Preview",
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <div
            style={{
              aspectRatio: "16/9",
              width: "100%",
            }}
          >
            {url && (
              <ReactPlayer
                url={url}
                controls={true}
                width={"100%"}
                height={"100%"}
                playing={true}
              />
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoPlayerModal;
