import { Button, Image, Td, useDisclosure } from "@chakra-ui/react";

import React from "react";
import useActions from "../../hooks/useActions";
import { deleteMovie, updateMovie } from "../../utils/api";
import useStore from "../../utils/store";
import Buttons from "../common/Actions/Buttons";
import CreateModal from "../common/Modal/Modal";
import VideoPlayerModal from "../common/Modal/VideoPlayerModal";

import CreateTable from "../common/Table/Table";
import Loading from "../common/ui/Loading";
import ModalSub from "../subscription/Modal";
import TableSub from "../subscription/Table";

const Table = ({
  allPodcasts,
  series,
  actions,
  reload,
  updatedSeriesPodcast,
  deleteSeriesPodcast,
  episeries,
  categories,
  subcategories,
}) => {
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = React.useState([]);
  const [typeId, setTypeId] = React.useState("");

  // Add state for video modals
  const [videoUrl, setVideoUrl] = React.useState("");
  const [videoTitle, setVideoTitle] = React.useState("Video Preview");
  const {
    isOpen: isVideoOpen,
    onOpen: onVideoOpen,
    onClose: onVideoClose,
  } = useDisclosure();

  const actionsI =
    actions && (user?.updateMovie || user?.deleteMovie) ? "Actions" : false;
  const [updateActionHandler, deleteAction] = useActions();

  // Function to handle video preview
  const handleVideoPreview = (url, title = "Video Preview") => {
    setVideoUrl(url);
    setVideoTitle(title);
    onVideoOpen();
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <VideoPlayerModal
        isOpen={isVideoOpen}
        onClose={onVideoClose}
        url={videoUrl}
        title={videoTitle}
      />

      <CreateTable
        searchby={
          !episeries && [
            "name",
            "language",
            "ageLimit",
            "rating",
            "subcategory",
          ]
        }
        subcategories={subcategories}
        pagging={episeries ? false : true}
        head={[
          actionsI,
          "Name",
          !episeries && "Views",
          "Description",
          !categories && "Subscription",
          "Url",
          "Promotional Video",
          "Trailer",
          "Horizontal-Banner",
          "Vertical-Banner",
          !series && "Duration",
          !series && !episeries && "Subcategory",

          !episeries && "Rating",
          !episeries && "Language",
          !episeries && "Age-Limit",
          !episeries && "Cast",
          !episeries && "extraCast",
          !episeries && "Genre",
        ]}
        data={allPodcasts}
        rows={(item) => {
          return (
            <>
              {actions && (user?.updateMovie || user?.deleteMovie) && (
                <Td>
                  {!series && (
                    <ModalSub
                      setLoading={(set) => {
                        setLoading(set);
                      }}
                      episeries={episeries}
                      item={item}
                      reload={reload}
                    />
                  )}
                  {!episeries && (
                    <Button
                      colorScheme={item.isEnable ? "red" : "green"}
                      variant="solid"
                      onClick={async () => {
                        if (!series) {
                          await updateActionHandler(
                            {
                              isEnable: !item.isEnable,
                            },
                            item._id,
                            updateMovie
                          );

                          reload();
                        }
                      }}
                      sx={{
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      {item.isEnable ? "Disable" : "Enable"}
                    </Button>
                  )}
                  <Buttons
                    episeries={episeries}
                    item={item}
                    update={async (data, id) => {
                      if (!series && !episeries) {
                        setLoading(true);
                        await updateActionHandler(data, id, updateMovie);
                        reload();
                        setLoading(false);
                      } else {
                        updatedSeriesPodcast(data, id);
                      }
                    }}
                    deletePodcast={async () => {
                      if (!series && !episeries) {
                        setLoading(true);
                        await deleteAction(item._id, deleteMovie);
                        reload();
                        setLoading(false);
                      } else {
                        deleteSeriesPodcast(item._id);
                      }
                    }}
                    subcategories={subcategories}
                  />{" "}
                  {!episeries && (
                    <Button
                      colorScheme={item.isFree ? "red" : "green"}
                      variant="solid"
                      onClick={async () => {
                        if (!series) {
                          await updateActionHandler(
                            {
                              isFree: !item.isFree,
                            },
                            item._id,
                            updateMovie
                          );

                          reload();
                        }
                      }}
                      sx={{
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      {item.isFree ? "Paid" : "Free"}
                    </Button>
                  )}
                </Td>
              )}
              <Td>{item?.name}</Td>
              {!episeries && <Td>{item?.view}</Td>}
              <Td>{item?.description.slice(0, 25)}...</Td>{" "}
              {!categories && (
                <Td>
                  <Button
                    colorScheme={"blue"}
                    onClick={() => {
                      onOpen();
                      setData(item.subscriptions);
                      setTypeId(item._id);
                    }}
                  >
                    View
                  </Button>
                </Td>
              )}
              <Td>
                <Button
                  colorScheme={"blue"}
                  variant={"link"}
                  onClick={() =>
                    handleVideoPreview(item?.url, "Main Content Preview")
                  }
                >
                  Preview
                </Button>
              </Td>
              <Td>
                <Button
                  colorScheme={"blue"}
                  variant={"link"}
                  onClick={() =>
                    handleVideoPreview(
                      item?.promotionalLink,
                      "Promotional Content Preview"
                    )
                  }
                >
                  Preview
                </Button>
              </Td>
              <Td>
                <Button
                  colorScheme={"blue"}
                  variant={"link"}
                  onClick={() =>
                    handleVideoPreview(item?.trailer, "Trailer Preview")
                  }
                >
                  Preview
                </Button>
              </Td>
              <Td>
                <Image
                  src={item?.horizontalBanner}
                  alt="horizontal-banner"
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                  rounded="md"
                />
              </Td>
              <Td>
                <Image
                  src={item?.verticalBanner}
                  alt="vertical-banner"
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                  rounded="md"
                />
              </Td>
              {!series && <Td>{item?.duration}</Td>}
              {!series && !episeries && <Td>{item?.subcategory}</Td>}
              {!episeries && (
                <>
                  <Td>{item?.rating}</Td>
                  <Td>{item?.language}</Td>
                  <Td>{item?.ageLimit}</Td>

                  <Td>
                    {item?.cast?.map((item, index) => {
                      return <p key={index}>{item.name}</p>;
                    })}
                  </Td>
                  <Td>{item?.extraCast}</Td>
                  <Td>
                    {item?.genres?.map((item, index) => {
                      return <p key={index}>{item.name}</p>;
                    })}
                  </Td>
                </>
              )}
            </>
          );
        }}
      />
      <CreateModal
        isOpen={isOpen}
        onClose={() => {
          setData([]);
          setTypeId("");
          onClose();
        }}
        title={"View Subscribtions"}
        table
      >
        <TableSub
          subscribtions={data}
          type="Movie"
          typeId={typeId}
          reload={reload}
          onClose={onClose}
        />
      </CreateModal>
    </>
  );
};

export default Table;
